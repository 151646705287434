<template>
  <v-form @submit.prevent="login">
    <v-sheet class="elevation-0 pl-0 pr-0 pt-0 pb-0 ">
      <v-card-title class="title font-weight-regular px-0">
        <h1
          :class="textColor"
          class="title font-weight-regular headline font-weight-medium"
        >
          {{ $vuetify.lang.t('$vuetify.general.login') }}
        </h1>
      </v-card-title>

      <v-window>
        <v-window-item :value="1">
          <v-card-text class="px-0">
            <v-text-field
              v-model="email"
              name="email"
              label="Email"
              type="email"
              autocomplete="on"
              outlined
              class="text-fields-theme"
              :class="primaryShadow === true ? 'primary-shadow-hack' : ''"
              required
            />
            <v-text-field
              v-model="password"
              name="password"
              :label="$vuetify.lang.t('$vuetify.general.password')"
              type="password"
              class="text-fields-theme"
              :class="primaryShadow === true ? 'primary-shadow-hack' : ''"
              autocomplete="on"
              outlined
              required
            />
            <h5 v-if="error" class="error--text">
              {{ error.message }}
            </h5>
            <v-checkbox
              v-model="rememberMe"
              :label="$vuetify.lang.t('$vuetify.loginForm.rememberMe')"
              :class="
                textColor ? 'checkbox-field-theme-2' : 'checkbox-field-theme'
              "
              class="mt-0"
              color="primary"
            />
          </v-card-text>
        </v-window-item>
      </v-window>
      <v-container d-flex class="pt-0">
        <v-row class="d-flex flex-row flex-wrap">
          <v-col cols="12" class="col-sm-6 d-flex align-center flex-wrap">
            <v-btn
              text
              small
              to="/unustasin-parooli"
              :class="textColor"
              class="text-capitalize"
            >
              {{ $vuetify.lang.t('$vuetify.general.forgotPassword') }}
            </v-btn>
            <v-spacer />
          </v-col>
          <v-col cols="12" class="col-sm-6 d-flex align-center flex-wrap">
            <v-btn
              :loading="loading.state === true && loading.type === 'login'"
              type="submit"
              :disabled="!email || !password"
              aria-label="Log in"
              :class="textColor"
              class="w-100 mb-4 rounded-10"
              outlined
            >
              {{ $vuetify.lang.t('$vuetify.general.login') }}
            </v-btn>
            <v-btn
              aria-label="Register"
              :class="textColor"
              class="w-100 rounded-10"
              outlined
              @click.prevent="didClickRegister()"
            >
              Liitu
            </v-btn>
          </v-col>
          <v-col class="text-center">
            <v-btn
              color="primary darken-3 main-border-radius"
              @click="signInWithFacebook()"
            >
              <font-awesome-icon
                :icon="['fab', 'facebook-square']"
                class="mr-4"
              />
              Logi sisse
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <!-- <v-spacer /> -->
      </v-card-actions>
    </v-sheet>
  </v-form>
</template>

<script>
import { isNil } from 'lodash'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Form',
  props: {
    textColor: {
      type: String,
      required: false,
      default: 'white--text'
    },
    primaryShadow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      email: '',
      password: '',
      get rememberMe() {
        return localStorage.getItem('rememberMe') === 'true'
      },
      // eslint-disable-next-line vue/no-dupe-keys
      set rememberMe(value) {
        localStorage.setItem('rememberMe', value)
      }
    }
  },
  computed: {
    ...mapGetters('app', ['error', 'loading']),
    ...mapGetters('authentication', ['user'])
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          if (isNil(this.$route.query.redirectUrl)) {
            if (this.$router.currentRoute.name !== 'event') {
              this.$router.push('/')
            }
          } else if (
            this.$router.currentRoute.name !== 'event' &&
            !isNil(this.$route.query.redirectUrl)
          ) {
            this.$router.push(this.$route.query.redirectUrl)
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('authentication', ['signInWithFacebook']),
    async login() {
      this.$store.dispatch('authentication/login', {
        email: this.email,
        password: this.password,
        rememberMe: this.rememberMe
      })
    },
    didClickRegister() {
      if (
        this.$router.currentRoute.name === 'login' ||
        this.$router.currentRoute.name === 'event'
      ) {
        this.$router.push('/registreeri')
      } else {
        this.$emit('didClickRegister')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.theme--light.v-sheet {
  background-color: rgba(255, 255, 255, 0);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 36px;
}
::v-deep .text-fields-theme .v-input__slot {
  background: #ffffff !important;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  border: 0 !important;
}

::v-deep .theme--light.v-btn.v-btn--disabled {
  border: 0;
}

::v-deep .primary-shadow-hack.text-fields-theme .v-input__slot {
  box-shadow: 4px 4px 20px rgba(1, 92, 137, 0.4) !important;
}
</style>
